import React from 'react';
import { useEffect } from 'react';
import Layout from '@components/Layout/Layout';
import SEO from '@components/seo';
import { inject } from '@vercel/analytics';
import Link from 'gatsby-link';

const NotFoundPage = () => {
  useEffect(() => {
    inject();
  }, []);

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function(e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>Opps, seems like you are lost!</h1>
      <p>
        Mind going back? or <Link to="/blog">read some blogs</Link>
      </p>
    </Layout>
  );
};

export default NotFoundPage;
